import BlueLogo from "../../img/imagens-png/logo-traffega.png";
import { BiLogoWhatsapp } from "react-icons/bi";
import "./NavbarComponent.css";
import { Component, useState } from "react";
import {Link} from 'react-router-dom'

function NavbarComponent(props) {
  const stateNavbar = props.navbar;
  const [navbar, setNavbar] = useState(stateNavbar);

  return (
    <>
      {
        <div className={ !navbar ? "navbarStructure" : "navbarStructureConfig"} style={{zIndex: 200}}>
          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid">
              <a className="navbar-brand" href="#">
                <img src={BlueLogo} alt="icon" className="logoNav" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
                style={{ boxShadow: "none" }}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <p className="verticalLine"></p>
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link to="/" className="nav-link" aria-current="page" href="/">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://amwgroup.com.br/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Group
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://ecomtraffega.com.br/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      E-com
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://amwgroup.com.br/about"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Sobre
                    </a>
                  </li>
                  <li className="nav-item lastCont">
                    <a
                      className="nav-link"
                      aria-disabled="true"
                      id="contate"
                      href="https://api.whatsapp.com/send?phone=5511992089747&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Traffega."
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contate-nos
                      <BiLogoWhatsapp className="logoWhatsapp" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      }
    </>
  );
}

export default NavbarComponent;
