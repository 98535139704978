// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./carousel.module.css";
import { Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";

export default () => {
  return (
    <Swiper
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
      breakpoints={{
        1024: {
          slidesPerView: 3,
          spaceBetween: 50,
        },
        769: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        610: {
          slidesPerView: 2,
          spaceBetween: 50,
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 50,
        },
      }}
      loop={true}
      slidesPerView={1}
      // onSlideChange={() => console.log("slide change")}
      // onSwiper={(swiper) => console.log(swiper)}
      className={styles.carouselMarketing}
    >
      <SwiperSlide className={styles.CardsMarketing}>
        <h2 className={styles.circleBlueMarketing}>1.</h2>
        <p className={styles.textCardsMarketing}>
          Crie jornadas personalizadas conectando clientes à sua marca, através
          de uma tecnologia inovadora.
        </p>
      </SwiperSlide>

      <SwiperSlide className={styles.CardsMarketing}>
        <h2 className={styles.circleBlueMarketing}>2.</h2>
        <p className={styles.textCardsMarketing}>
          Acelere suas vendas e faça seu negócio crescer em uma única
          plataforma.
        </p>
      </SwiperSlide>

      <SwiperSlide className={styles.CardsMarketing}>
        <h2 className={styles.circleBlueMarketing}>3.</h2>
        <p className={styles.textCardsMarketing}>
          Tenha acesso a todos os canais digitais com automação de marketing
          avançada que você precisa.
        </p>
      </SwiperSlide>

      <SwiperSlide className={styles.CardsMarketing}>
        <h2 className={styles.circleBlueMarketing}>4.</h2>
        <p className={styles.textCardsMarketing}>
          Crie jornadas personalizadas conectando clientes à sua marca, através
          de uma tecnologia inovadora.
        </p>
      </SwiperSlide>

      <SwiperSlide className={styles.CardsMarketing}>
        <h2 className={styles.circleBlueMarketing}>5.</h2>
        <p className={styles.textCardsMarketing}>
          Envolva os clientes certos por meio do rastreamento de comportamento
          de navegação. Crie jornadas de clientes e aborde cada visitante de
          acordo com seu interesse.
        </p>
      </SwiperSlide>

      <SwiperSlide className={styles.CardsMarketing}>
        <h2 className={styles.circleBlueMarketing}>6.</h2>
        <p className={styles.textCardsMarketing}>
          Dimensione e crie facilmente campanhas de marketing automatizadas.
          Segmente seus contatos utilizando fluxos para automação avançada com
          visão de 360º.
        </p>
      </SwiperSlide>

      <SwiperSlide className={styles.CardsMarketing}>
        <h2 className={styles.circleBlueMarketing}>7.</h2>
        <p className={styles.textCardsMarketing}>
          Aproveite dados comportamentais avançados, inteligência integrada e
          fluxos de jornada. Envie mensagens personalizadas de acordo com o
          interesse, na hora certa.
        </p>
      </SwiperSlide>

      <SwiperSlide className={styles.CardsMarketing}>
        <h2 className={styles.circleBlueMarketing}>8.</h2>
        <p className={styles.textCardsMarketing}>
          Determine como o marketing de automação e os canais digitais irão
          aumentar o seu ROI. Saiba como aumentar a taxa de conversão de seus
          leads
        </p>
      </SwiperSlide>

      <SwiperSlide className={styles.CardsMarketing}>
        <h2 className={styles.circleBlueMarketing}>9.</h2>
        <p className={styles.textCardsMarketing}>
          Descubra por que as inovações contínuas da Traffega impulsionam
          resultados mais rápidos. Gere leads e aumente a sua receita (ROI).
        </p>
      </SwiperSlide>
    </Swiper>
  );
};
