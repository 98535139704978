import styles from "./footer.module.css";
import logo from "../../img/imagens-png/logo-traffega.png";

import { LiaLinkedinIn } from "react-icons/lia";
import { ImFacebook } from "react-icons/im";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";

const Footer = () => {
  function toTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  return (
    <div className={styles.mainFooter}>
      <div className={styles.logo}>
        <img src={logo} alt="logo" onClick={toTop} />
      </div>

      <div className={styles.copyright}>
        <p>
          Copyright 2023 &copy; AMW GROUP&nbsp; 
          <br className={styles.br} />-<br className={styles.br} />
          &nbsp;Política de privacidade
        </p>
        <p>Todos os direitos reservados</p>
      </div>

      <div className={styles.redesSociais}>
        <a
          href="https://www.linkedin.com/company/traffegaweb/"
          target="_blank"
          rel="noreferrer"
        >
          <LiaLinkedinIn className={styles.iconFooter} />
        </a>
        <a
          href="https://www.facebook.com/traffegaoficial"
          target="_blank"
          rel="noreferrer"
        >
          <ImFacebook className={styles.iconFooter} />
        </a>
        <a
          href="https://www.instagram.com/traffega/"
          target="_blank"
          rel="noreferrer"
        >
          <AiOutlineInstagram className={styles.iconFooter} />
        </a>
        <a
          href="https://www.youtube.com/@Traffega/featured"
          target="_blank"
          rel="noreferrer"
        >
          <AiFillYoutube className={styles.iconFooter} />
        </a>
      </div>
    </div>
  );
};

export default Footer;
