/* ===== components ===== */
import React from "react";
import Carousel from "./../carouselMarketing/carouselMarketing";
/* ===== Styles ===== */
import styles from "./marketing.module.css";

import backgroundMarketing from "./../../img/imagens-png/backgroundMarketing.png";

const marketing = () => {
  return (
    <div
      className={styles.backgroundMarketing}
      style={{ position: "relative", zIndex: "1" }}
    >
      <h1 className={styles.titleMarketing}>
        Tudo isso graças ao marketing digital{" "}
        <br className={styles.breakLine} /> baseado em dados.
      </h1>

      <Carousel />
      <div className={styles.divCardsMarketing}>
        <div className={styles.cardsMarketing}>
          <h2 className={styles.circleBlue}>1.</h2>
          <p className={styles.textCards}>
            Crie jornadas personalizadas conectando clientes à sua marca,
            através de uma tecnologia inovadora.
          </p>
        </div>

        <div className={styles.cardsMarketing}>
          <h2 className={styles.circleBlue}>2.</h2>
          <p className={styles.textCards}>
            Acelere suas vendas e faça seu negócio crescer em uma única
            plataforma.
          </p>
        </div>

        <div className={styles.cardsMarketing}>
          <h2 className={styles.circleBlue}>3.</h2>
          <p className={styles.textCards}>
            Tenha acesso a todos os canais digitais com automação de marketing
            avançada que você precisa.
          </p>
        </div>

        <div className={styles.cardsMarketing}>
          <h2 className={styles.circleBlue}>4.</h2>
          <p className={styles.textCards}>
            Entenda, segmente, ative, e crie campanhas de automação
            personalizada através do comportamento de dados, alavancando seu
            desempenho e refletindo de forma significativa o ROI.
          </p>
        </div>

        <div className={styles.cardsMarketing}>
          <h2 className={styles.circleBlue}>5.</h2>
          <p className={styles.textCards}>
            Envolva os clientes certos por meio do rastreamento de comportamento
            de navegação. Crie jornadas de clientes e aborde cada visitante de
            acordo com seu interesse.
          </p>
        </div>

        <div className={styles.cardsMarketing}>
          <h2 className={styles.circleBlue}>6.</h2>
          <p className={styles.textCards}>
            Dimensione e crie facilmente campanhas de marketing automatizadas.
            Segmente seus contatos utilizando fluxos para automação avançada com
            visão de 360º.
          </p>
        </div>

        <div className={styles.cardsMarketing}>
          <h2 className={styles.circleBlue}>7.</h2>
          <p className={styles.textCards}>
            Aproveite dados comportamentais avançados, inteligência integrada e
            fluxos de jornada. Envie mensagens personalizadas de acordo com o
            interesse, na hora certa.
          </p>
        </div>

        <div className={styles.cardsMarketing}>
          <h2 className={styles.circleBlue}>8.</h2>
          <p className={styles.textCards}>
            Determine como o marketing de automação e os canais digitais irão
            aumentar o seu ROI. Saiba como aumentar a taxa de conversão de seus
            leads
          </p>
        </div>

        <div className={styles.cardsMarketing}>
          <h2 className={styles.circleBlue}>9.</h2>
          <p className={styles.textCards}>
            Descubra por que as inovações contínuas da Traffega impulsionam
            resultados mais rápidos. Gere leads e aumente a sua receita (ROI).
          </p>
        </div>
      </div>
    </div>
  );
};

export default marketing;
