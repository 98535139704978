import React from 'react'

import styles from './demonstration.module.css';

const demonstration = () => {
  function toBottom() {
    window.scrollTo(0,  document.body.scrollHeight);
  }

  return (
    <div className={styles.background}>
        <div className={styles.mainDemonstration}>
            <h1 className={styles.titleDemonstration}>Onde o <span className={styles.strongFont}>Crescimento</span><br /> da sua <span  className={styles.strongFont}>empresa</span> acontece</h1>
            <p className={styles.paragraphDemonstration}>Plataforma de automação de marketing digital<br/> com IA (Inteligência Artificial) Business Intelligence</p>
        </div>
        <button onClick={toBottom} className={styles.btnDemonstration}>Fale Conosco</button>
    </div>
  )
}

export default demonstration