import styles from "./ScrollTop.module.css";
import { IoIosArrowUp } from "react-icons/io";
import { useState } from "react";

const ScrollTop = () => {
  const [stateButton, setStateButton] = useState(false);
  const [transition, setTransition] = useState(false);

  const handleTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 800) {
      setStateButton(true);
      setTransition(true);
    } else {
      setStateButton(false);
      setTransition(false);
    }
    // console.log(`Janela: ${window.scrollY}`)
  });

  return (
    <>
      {!stateButton ? (
        " "
      ) : (
        <div onClick={handleTop} className={`${styles.mainScroll} ${transition} ? ${styles.transition} : ""`}>
          <IoIosArrowUp />
        </div>
      )}
    </>
  );
};

export default ScrollTop;
