import React from "react";
// import styles from "./canvas.module.css";

import particlesJSv2 from "particles.js";
import Stats from "./../../../node_modules/particles.js/demo/js/lib/stats";
import { useEffect } from "react";
import "./../../../node_modules/particles.js/demo/css/style.css";

const Canvas = () => {
  window.Stats = Stats;

  useEffect(() => {
    // console.log("particlesJSv2", particlesJSv2);
    window.particlesJSv2 = window.particlesJS;

    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 100,
          density: {
            enable: true,
            value_area: 1000,
          },
        },
        color: {
          value: "#21aabe",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#303866",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 3,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#1fa5bf",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 2,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "repulse",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 100,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  }, []);

  return (
    <div>
      <div
        id="particles-js"
        style={{
          background:
            "radial-gradient(circle, rgba(38,44,81,1) 0%, rgba(7,8,24,1) 100%)",
          position: "fixed",
          width: "100%",
        }}
      ></div>
      <div class="count-particles">
        <span class="js-count-particles" style={{ border: "none" }}></span>
      </div>
    </div>
  );
};

export default Canvas;
