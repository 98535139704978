import styles from "./PrivacyPolicy.module.css";
import NavbarComponent from "../../components/Navbar/NavbarComponents";
import Footer from "../../components/footer/footer";

const PrivacyPolicy = () => {
  return (
    <>
      <div className={styles.containerPolicy}>
        <NavbarComponent navbar={false}/>
        <section className={styles.mainPolicyOne}>
          <div className={styles.contentPolicyOne}>
            <h1>
              Política de Privacidade <span>| Traffega</span>
            </h1>
            <div className={styles.intro}>
              <p>
                Um dos pilares fundamentais é o compromisso com a segurança de
                dados e privacidade do usuário final, assim como dos seus
                clientes. Conforme legislação brasileira que regula as
                atividades de tratamento de dados pessoais, sendo a LGPD: Lei
                Geral de Proteção de Dados.
              </p>

              <p>
                É a legislação brasileira sobre o tratamento de dados pessoais,
                com vigência a partir de 16/08/2020. Coletamos dados anônimos da
                navegação do site, sem associá-los à sua identidade. Os sites
                que utilizam a TRAFFEGA devem mencionar em sua política de
                privacidade que tecnologia de terceiros está sendo utilizada
                para coleta de dados, determinando os fins que serão utilizados.
                Não efetuamos coleta de dados pessoais identificáveis sem que
                haja o prévio e expresso consentimento por parte do usuário.
              </p>

              <p>
                Nossa tecnologia utiliza o método de gravação de cookies para
                viabilizar as operações de coleta de dados online. Cookies são
                arquivos salvos no seu computador para viabilizar a coleta de
                dados em seu navegador de internet, coletados única e
                exclusivamente para fins analíticos ou para customização de
                publicidade e conteúdo.
              </p>

              <p>
                Este site é de propriedade da TRAFFEGA com nome social AMW
                SISTEMAS, sociedade privada, inscrita no CNPJ/MF sob o n.
                18.780.360/0001-19, com endereço sede na Av. Queiroz Filho,
                1700, n°206, Torre A - Vila Hamburguesa, São Paulo - SP,
                05319-000
              </p>

              <p>
                Pela presente Política de Privacidade vamos informar-lhe como as
                suas informações e dados serão recolhidos, usados, partilhados e
                armazenados.
              </p>
              <hr />
            </div>
            <div className={styles.subtitles}>
              <h4>O Serviço da TRAFFEGA</h4>
              <p>
                Fornecemos um conjunto de serviços de automação de marketing
                digital, relacionados com várias formas de interações de
                marketing com o cliente, sendo canais de ativação como: e-mail
                marketing; whatsapp; whatsapp business; retargeting; overlay;
                landing page; formulários; dentre outros, com capacidades de
                armazenamento, e estatísticas que se baseiam em ferramentas de
                proprietário, tecnologia e habilidade (“Serviço”). O Serviço é
                fornecido a empresas ou agências (“Clientes”) nos termos do
                acordo, e que se sujeitam ao pagamento de uma mensalidade. O
                Serviço é fornecido através de uma área restrita acessível
                somente para Clientes.
              </p>
              <h4>A Plataforma TRAFFEGA</h4>
              <p>
                Em conexão com o Serviço da TRAFFEGA desenvolvemos e continuamos
                operando no nosso site, conteúdos especializados, relatórios,
                aplicativos de software, páginas de mídia social, mas também
                utilizamos outras formas de interação. O Serviço da TRAFFEGA
                tais como e-mails; newsletters; ativações on-site; whatsapp
                business; ligações de telefone; eventos de marketing e
                participações em feiras comerciais. Todos estes métodos de
                promoção e venda do Serviço da TRAFFEGA e chamado como
                “Plataforma”.
              </p>
              <h4>Termos de Uso do Site da TRAFFEGA</h4>
              <p>
                Esse documento de privacidade também é incorporado aos Termos de
                Uso da TRAFFEGA para visitantes da plataforma. Definições para
                os fins de esclarecimentos de Privacidade, “Cliente” significa
                uma empresa que é, foi, ou está prestes a se tornar um cliente
                pagante.
              </p>
              <ul>
                <li>
                  “Visitante” significa um sujeito/indivíduo que visita a
                  Plataforma, e que pode estar procurando informações sobre o
                  Serviço.
                </li>
                <li>
                  “Lead” significa um visitante que demonstrou interesse em
                  nossos produtos ou serviços, por exemplo através do
                  preenchimento de um formulário, ou uma demonstração da
                  Plataforma; ou um visitante que interage conosco, de qualquer
                  maneira, sobre o nosso Serviço, por exemplo em e-mails,
                  ligações por telefone ou em conversas pessoais.
                </li>
                <li>
                  “Usuário” significa um visitante, um lead, cliente, empresa ou
                  agência que utiliza ou está testando a plataforma.
                </li>
              </ul>

              <h4>Cookies, Tags e Outras Tecnologias</h4>
              <p>
                Algumas informações são coletadas através de cookies. Cookies,
                tags e outras tecnologias de rastreamento que são utilizadas na
                nossa Plataforma TRAFFEGA para garantir que tudo funcione
                adequadamente, e para oferecer aos usuários uma experiência mais
                personalizada.
              </p>

              <p>
                Nós acreditamos que tais meios são justos, legais, e
                proporcionais aos interesses e às necessidades de nossa empresa.
                Acreditamos que a nossa metodologia abarca os legítimos direitos
                e expectativas de cada usuário, tendo em vista o contexto e
                propósito da coleta e utilização das informações coletadas.
              </p>
              <h4>Fontes Públicas </h4>
              <p>
                Algumas informações são obtidas através de registros públicos e
                bases de dados públicos disponíveis.
              </p>
              <h4>O Uso que os Clientes Fazem de Nosso Serviço</h4>
              <p>
                Esta nota de privacidade não se aplica à coleta ou ao
                processamento de dados pessoais que ocorre quando nossos
                clientes utilizam o serviço para suas próprias finalidades. A
                coleta e o processamento de dados pessoais por parte de nossos
                clientes, quando utilizam nosso Serviço, estão sujeitos à
                política de privacidade destes clientes, como postado e
                informado em seus respectivos sites. Nós não controlamos o tipo
                de informações que nossos clientes podem escolher coletar ou
                gerenciar utilizando nosso serviço. Essas informações são
                possuídas por eles, e são usadas, divulgadas e processadas
                somente por eles, ou em seus nomes, de acordo com as suas
                instruções e política. Nós não somos responsáveis pelo uso que
                nossos clientes fazem dos dados pessoais que coletam utilizando
                nosso serviço.
              </p>
              <h4>Serviços de Terceiros</h4>
              <p>
                Esta nota de privacidade também não se aplica a qualquer site,
                serviço ou botão de mídia social de terceiros (“Serviço de
                Terceiros”) que podem estar ligados à plataforma, tais como
                links em um artigo ou determinados botões disponíveis em redes
                sociais (como por exemplo: Facebook, Instagram, Twitter ou
                qualquer rede social que utilize e aplique esta tecnologia) A
                modo de esclarecimento e a fim de evitar dúvidas, nós não temos
                controle sobre estes serviços de terceiros, e ao mesmo tempo não
                somos responsáveis pela coletas de dados e/ou seu uso.
                Encorajamos a ler as suas declarações de privacidade desses
                serviços de terceiros. A inclusão de um link para um Serviço de
                terceiro não quer dizer que endossamos quaisquer um dos
                produtos, serviços ou práticas de uso de terceiros; ou que esta
                nota de privacidade encobre os produtos, serviços ou práticas de
                terceiros.
              </p>
              <h4>Atualizações ou alterações na Política de Privacidade</h4>
              <p>
                Toda vez que você acessar a Plataforma TRAFFEGA, a versão atual
                de Política de Privacidade será aplicada. Nós recomendamos que
                você verifique de forma periódica esta página, e revise
                quaisquer alterações desde a última vez que você usou a
                plataforma. O seu acesso de forma contínua ou uso da Plataforma
                TRAFFEGA, após termos efetuado as devidas alterações nesta
                Política de Privacidade, como mencionado acima, e, por outro
                lado, correspondido com as leis aplicáveis sobre mudanças nela,
                indicará que você concorda a se sujeitar a tais alterações Caso
                você opte por não concordar com tais alterações, você deve
                cessar o acesso ao uso da Plataforma TRAFFEGA, e buscar a
                deleção de suas informações captadas pelo uso, como descrito
                acima. Em alguns casos, o seu consentimento atual poderá ser
                solicitado. Antes de usar informações para outros fins que não
                estejam definidos nesta Política de Privacidade, solicitamos sua
                autorização.
              </p>
              <p>
                Para esclarecimento de dúvidas em relação à nossa política de
                privacidade entre em contato conosco.
              </p>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
