//Components
import React from "react";
import Carousel from "./../carouselClient/carouselBackgroundText";
import CarouselCards from "./../carouselClient/carouselCards";
//Styles
import styles from "./client.module.css";

const client = () => {
  return (
    <div className={styles.backgroundContainer}>
      <div className={styles.backgroundClient}>
        <div className={styles.containerTextBackground}>
          <h1 className={styles.titleClient}>
            Entenda seu cliente, otimize resultados.
          </h1>
          <div className={styles.textBackground}>
            <Carousel />
          </div>
        </div>
      </div>

      <div className={styles.backgroundProducts}>
        <div className={styles.containerProducts}>
          <CarouselCards />
          <div className={styles.containerCardsProducts}>
            <div className={styles.cardsProducts}>
              <div className={styles.circle}>
                <svg
                  id="_002"
                  data-name="002"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 160 150"
                >
                  <path
                    class="cls-1"
                    d="m142.58,58.77c2.19,2.19,3.28,4.8,3.28,7.85s-1.09,5.66-3.28,7.85l-24.84,24.84c-2.03,2.03-4.49,3.05-7.38,3.05s-5.35-1.02-7.38-3.05l-6.09-5.86c-1.56-1.56-2.58-3.44-3.05-5.62s-.31-4.3.47-6.33l-2.34-2.34-13.36,13.36c2.5,2.5,3.75,5.27,3.75,8.32s-1.09,5.66-3.28,7.85l-25.31,25.31c-2.19,2.19-4.84,3.28-7.97,3.28s-5.78-1.09-7.97-3.28l-8.67-8.67c-2.19-2.19-3.28-4.84-3.28-7.97s1.09-5.78,3.28-7.97l25.31-25.31c2.19-2.19,4.8-3.28,7.85-3.28s5.82,1.25,8.32,3.75l13.36-13.36-2.34-2.34c-2.03.78-4.14.94-6.33.47s-4.06-1.48-5.62-3.05l-5.39-5.39c-2.19-2.19-3.28-4.84-3.28-7.97s1.09-5.78,3.28-7.97l24.38-24.38c2.19-2.19,4.8-3.28,7.85-3.28s5.66,1.09,7.85,3.28l5.62,5.39c1.56,1.56,2.54,3.44,2.93,5.62s.27,4.3-.35,6.33l12.66,12.66c2.03-.62,4.14-.74,6.33-.35s4.06,1.37,5.62,2.93l5.39,5.62ZM45.79,126.03l25.31-25.31-8.67-8.67-25.31,25.31,8.67,8.67Zm64.45-35.16l24.38-24.14-5.39-5.62-3.98,3.98-27.19-27.19,3.98-3.98-5.62-5.39-24.14,24.38,5.39,5.39,3.52-3.52,27.19,27.19-3.52,3.52,5.39,5.39Z"
                  />
                </svg>
              </div>
              <h2 className={styles.titleCardProduct}>ROI otimizado</h2>
              <p className={styles.paragraphCardProduct}>
                Oferece um melhor processo de tomada de decisão que pode trazer
                um ótimo retorno sobre o investimento.
              </p>
            </div>

            <div className={styles.cardsProducts}>
              <div className={styles.circle}>
                <svg
                  id="_002"
                  data-name="002"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 160 150"
                >
                  <path
                    class="cls-1"
                    d="m147.5,67.54c0,2.19-.7,4.14-2.11,5.86s-3.2,2.81-5.39,3.28v32.11c0,3.12-1.09,5.78-3.28,7.97s-4.84,3.28-7.97,3.28c-9.53-8.28-18.2-14.53-26.02-18.75-11.72-6.56-23.67-10.23-35.86-11.02-1.72.94-2.89,2.7-3.52,5.27s-.31,4.49.94,5.74c.78.94,1.25,1.99,1.41,3.16s-.08,2.3-.7,3.4-.94,1.91-.94,2.46.47,1.29,1.41,2.23c.62.62,2.19,1.95,4.69,3.98l1.88,1.64c.94.78,1.56,1.8,1.88,3.05s.16,2.5-.47,3.75c-1.88,3.91-5.16,6.72-9.84,8.44-4.53,1.56-9.26,1.95-14.18,1.17s-8.87-2.58-11.84-5.39c-.62-.62-1.09-1.41-1.41-2.34l-1.41-4.69c-2.19-6.56-3.59-11.56-4.22-15-1.25-5.94-1.48-11.64-.7-17.11h-6.09c-3.12,0-5.78-1.09-7.97-3.28s-3.28-4.84-3.28-7.97v-22.5c0-3.12,1.09-5.78,3.28-7.97s4.84-3.28,7.97-3.28h37.5c13.44,0,26.56-3.36,39.38-10.08,8.44-4.38,17.81-11.02,28.12-19.92,3.12,0,5.78,1.09,7.97,3.28s3.28,4.84,3.28,7.97v32.11c2.19.47,3.98,1.56,5.39,3.28s2.11,3.67,2.11,5.86Zm-123.75,11.25h37.5v-22.5H23.75v22.5Zm22.73,43.12c1.56,1.09,3.67,1.72,6.33,1.88s5-.16,7.03-.94c-2.97-2.66-5-5.23-6.09-7.73-1.25-2.97-1.25-6.17,0-9.61-1.25-2.34-1.88-4.88-1.88-7.62s.47-5.35,1.41-7.85h-12.19c-.78,4.53-.7,9.38.23,14.53.62,3.12,1.95,7.73,3.98,13.83.47,1.56.86,2.73,1.17,3.52Zm82.27-16.64V29.8c-9.06,7.19-17.89,12.81-26.48,16.88-9.69,4.69-19.61,7.66-29.77,8.91v23.91c10.16,1.25,20.08,4.22,29.77,8.91,8.59,4.06,17.42,9.69,26.48,16.88Z"
                  />
                </svg>
              </div>
              <h2 className={styles.titleCardProduct}>
                Remarketing / Retargeting
              </h2>
              <p className={styles.paragraphCardProduct}>
                Você pode gerar ações voltadas para re-impactar clientes que
                estiveram perto da compra, mas acabaram desistindo.
              </p>
            </div>

            <div className={styles.cardsProducts}>
              <div className={styles.circle}>
                <svg
                  id="_002"
                  data-name="002"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 160 150"
                >
                  <path
                    class="cls-1"
                    d="m149.38,88.77c1.56,0,2.89.55,3.98,1.64s1.64,2.42,1.64,3.98v33.75c0,1.56-.55,2.89-1.64,3.98s-2.42,1.64-3.98,1.64h-33.75c-1.56,0-2.89-.55-3.98-1.64s-1.64-2.42-1.64-3.98v-33.75c0-1.56.55-2.89,1.64-3.98s2.42-1.64,3.98-1.64h11.25v-9.38h-41.25v9.38h11.25c1.56,0,2.89.55,3.98,1.64s1.64,2.42,1.64,3.98v33.75c0,1.56-.55,2.89-1.64,3.98s-2.42,1.64-3.98,1.64h-33.75c-1.56,0-2.89-.55-3.98-1.64s-1.64-2.42-1.64-3.98v-33.75c0-1.56.55-2.89,1.64-3.98s2.42-1.64,3.98-1.64h11.25v-9.38h-41.25v9.38h11.25c1.56,0,2.89.55,3.98,1.64s1.64,2.42,1.64,3.98v33.75c0,1.56-.55,2.89-1.64,3.98s-2.42,1.64-3.98,1.64H10.62c-1.56,0-2.89-.55-3.98-1.64s-1.64-2.42-1.64-3.98v-33.75c0-1.56.55-2.89,1.64-3.98s2.42-1.64,3.98-1.64h11.25v-11.25c0-2.66.9-4.88,2.7-6.68s4.02-2.7,6.68-2.7h43.12v-9.38h-11.25c-1.56,0-2.89-.55-3.98-1.64s-1.64-2.42-1.64-3.98V19.4c0-1.56.55-2.89,1.64-3.98s2.42-1.64,3.98-1.64h33.75c1.56,0,2.89.55,3.98,1.64s1.64,2.42,1.64,3.98v33.75c0,1.56-.55,2.89-1.64,3.98s-2.42,1.64-3.98,1.64h-11.25v9.38h43.12c2.66,0,4.88.9,6.68,2.7s2.7,4.02,2.7,6.68v11.25h11.25Zm-110.62,11.25h-22.5v22.5h22.5v-22.5Zm30-52.5h22.5v-22.5h-22.5v22.5Zm22.5,52.5h-22.5v22.5h22.5v-22.5Zm52.5,22.5v-22.5h-22.5v22.5h22.5Z"
                  />
                </svg>
              </div>
              <h2 className={styles.titleCardProduct}>
                Segmente seus consumidores
              </h2>
              <p className={styles.paragraphCardProduct}>
                Por reports customizado, gere coletas de dados inteligentes,
                baseada em informações, navegação e interação do seu público
                alvo.
              </p>
            </div>
          </div>

          <p className={styles.paragraphFinal}>
            Especializada em comportamento de dados, a <span>Traffega</span> usa inteligência
            artificial (IA), big-data e humanização para ajudar as empresas a
            melhorar sua performance, plataforma única, onde você entende,
            segmenta seus dados e cria campanhas automáticas e altamente
            personalizadas.
          </p>
        </div>
      </div>
    </div>
  );
};

export default client;
