import Cookies from '../../components/CookieBar/cookieBar';
import Navbar from '../../components/Navbar/NavbarComponents.jsx';
import Demonstration from '../../components/demonstration/demonstration.jsx';
import SalesDeveloper from '../../components/sales/salesDeveloper.jsx';
import Marketing from '../../components/marketing/marketing.jsx';
import Client from '../../components/cliente/client.jsx';
import Forms from '../../pages/section';
import Footer from '../../components/footer/footer.jsx';
import Scroll from '../../components/ScrollTop/ScrollTop.jsx';
import WhatsAppButton from "../../components/WhatsAppButton/WhatsAppButton"
import Canvas from '../canvas';

const Default = () => {
  return (
    <div> 
    <Canvas />
    <Cookies />
    <WhatsAppButton/>
    <Scroll />
    <Navbar navbar={false}/>
    <Demonstration />
    <SalesDeveloper />
    <Marketing />
    <Client />
    <Forms />
    <Footer /></div>
  )
}

export default Default