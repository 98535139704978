import styles from "./WhatsAppButton.module.css";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { useState } from "react";

const WhatsAppButton = () => {
  const [stateButton, setStateButton] = useState(false);
  const [transition, setTransition] = useState(false);

  window.addEventListener("scroll", () => {
    const scrollPosition = window.scrollY;
    if (scrollPosition > 800) {
      setStateButton(true);
      setTransition(true);
    } else {
      setStateButton(false);
      setTransition(false);
    }
    // console.log(`Janela: ${window.scrollY}`)
  });

  return (
    <>
      {!stateButton ? (
        " "
      ) : (
        <a href="https://api.whatsapp.com/send?phone=5511992089747&text=Ol%C3%A1,%20gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es%20sobre%20a%20Traffega." target="_blank" className={`${styles.mainWhatsApp} ${transition} ? ${styles.transition} : ""`}>
          <AiOutlineWhatsApp />
        </a>
      )}
    </>
  );
};

export default WhatsAppButton;
