import Forms from "../../assets/components/forms/Forms";
import styles from "./section.module.css";

const section = () => {
  return (
    <div className={styles.containerForms}>
      <Forms />
    </div>
  );
};

export default section;