/* ===== Components ===== */
import DefaultPage from './assets/pages/DefaultPage/Default'

import { BrowserRouter, Routes, Route } from 'react-router-dom';

/* ===== Styles ===== */
import './App.css';
import PrivacyPolicy from './assets/pages/PrivacyPolicy/PrivacyPolicy';

/* ===== Website Code ===== */
function App() {

  return (
    <div className="App">
     <BrowserRouter>
     <Routes>
      <Route path='/' element={<DefaultPage/>} />
      <Route path='/policies' element={<PrivacyPolicy/>} />
      <Route path='*' element={<DefaultPage/>} />
     </Routes>
     </BrowserRouter>
    </div>
  );
}

export default App;
