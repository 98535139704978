// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import styles from './carouselBackgroundText.module.css';

export default () => {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 6000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      // navigation={true}
      modules={[Autoplay]}
      loop={true}
      slidesPerView={1}
      // onSlideChange={() => console.log("slide change")}
      // onSwiper={(swiper) => console.log(swiper)}
      className={styles.carouselDivParagraph}
      // style={{position: "relative", top: "0px"}}
    >
      <SwiperSlide>
        <p className={styles.paragraphCarousel}>
          Envolva seus clientes com campanhas segmentadas e <br className={styles.breakLine}/> personalizadas
          exclusivas a partir de seus comportamentos on-line, <br className={styles.breakLine}/> onde quer que
          estejam.
        </p>
      </SwiperSlide>
      <SwiperSlide>
        <p className={styles.paragraphCarousel}>
          Gere melhores resultados explorando o comportamento de seus <br className={styles.breakLine}/> clientes
          para enviar dinamicamente a mensagem certa no <br className={styles.breakLine}/> momento certo. Use
          testes A/B com conteúdos preditivos para <br className={styles.breakLine}/> melhorar os resultados e
          conversões.
        </p>
      </SwiperSlide>
      <SwiperSlide>
        <p className={styles.paragraphCarousel}>
          Avalie o desempenho e resultados das campanhas de automação <br className={styles.breakLine}/> de
          marketing a qualquer hora, em qualquer lugar. Obtenha o <br className={styles.breakLine}/> máximo das
          métricas de desempenho e indicadores.
        </p>
      </SwiperSlide>
      <SwiperSlide>
        <p className={styles.paragraphCarousel}>
          Saiba o que está funcionando e o que não está e, em seguida, use <br className={styles.breakLine}/> essas
          informações para gerar receita e mergulhe no verdadeiro <br className={styles.breakLine}/> impacto de
          seus resultados.
        </p>
      </SwiperSlide>
      <SwiperSlide>
        <p className={styles.paragraphCarousel}>
          Conecte e unifique dados coletados automaticamente com base <br className={styles.breakLine}/> em
          insights e filtros por meio de segmentações avançadas <br className={styles.breakLine}/> comportamentais.
        </p>
      </SwiperSlide>
    </Swiper>
  );
};
